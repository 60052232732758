<template>
  <transition name="events-page-prompt">
    <div
      v-show="hasSelectedEvents"
      class="events-page-prompt"
    >
      <div
        v-if="selectedEventsLabel"
        class="events-page-prompt-box"
      >
        {{ selectedEventsLabel }}
      </div>
      <div class="events-page-prompt-box">
        <Button
          variant="tertiary"
          @click="clearSelection"
        >
          Clear selection
        </Button>
        <Button
          v-if="isEventsPage && hasEventsToBook"
          :variant="eventsToBook.length !== selectedEvents.length ? 'secondary' : 'primary'"
          @click="bookSelection"
          :loading="processing"
        >
          {{ bookEventsLabel }}
        </Button>

        <Button
          class="suspend-btn"
          v-if="isEventsPage && hasEventsToSuspend"
          variant="secondary"
          @click="suspendSelection"
          :loading="processing"
        >
          <Icon name="pause" />
          {{ suspendEventsLabel }}
        </Button>
        <Button
          class="unsuspend-btn"
          v-if="isEventsPage && hasEventsToUnsuspend"
          variant="secondary"
          @click="unsuspendSelection"
          :loading="processing"
        >
          <Icon name="play" />
          {{ unsuspendEventsLabel }}
        </Button>
        <button
          v-if="isEventsPage && hasSelectedEvents"
          class="open-multiview-button"
          @click="openTradingView"
        >
          <Icon name="link-external" />
          {{ tradingButtonLabel }}
        </button>
      </div>
    </div>
  </transition>
</template>

<script>
import { computed, ref } from 'vue';
import { useStore } from 'vuex';
import { useRoute, useRouter } from 'vue-router';
import { map, filter } from 'lodash';
import Icon from '@/components/common/Icon';
import Button from '@/components/common/Button';
import { bookAllFeaturesEvent } from '@/services/api';

export default {
  components: {
    Icon,
    Button,
  },
  setup() {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();

    const processing = ref(false);

    const selectedEvents = computed(() => store.getters.selectedEvents);
    const hasSelectedEvents = computed(() => !!selectedEvents.value.length);

    const eventsToBook = computed(() => filter(selectedEvents.value, (selectedEvent) => !selectedEvent.booked && selectedEvent.matchState !== 'FINISHED'));
    const hasEventsToBook = computed(() => !!eventsToBook.value.length);
    const eventsToSuspend = computed(() => filter(selectedEvents.value, (selectedEvent) => !selectedEvent.isSuspended && selectedEvent.booked && selectedEvent.matchState !== 'FINISHED'));
    const hasEventsToSuspend = computed(() => !!eventsToSuspend.value.length);
    const eventsToUnsuspend = computed(() => filter(selectedEvents.value, (selectedEvent) => selectedEvent.isSuspended && selectedEvent.booked && selectedEvent.matchState !== 'FINISHED'));
    const hasEventsToUnsuspend = computed(() => !!eventsToUnsuspend.value.length);
    const isEventsPage = computed(() => route.name === 'events_book_events');

    const selectedEventsLabel = computed(() => {
      let label = `${selectedEvents.value.length} event`;
      if (!selectedEvents.value.length) label = 'No events';
      if (selectedEvents.value?.length > 1) label += 's';
      return `${label} selected`;
    });

    const bookEventsLabel = computed(() => {
      if (hasEventsToSuspend.value || selectedEvents.value.length !== eventsToBook.value.length) {
        let label = `Book ${eventsToBook.value.length} event`;
        if (eventsToBook.value?.length > 1) label += 's';
        return `${label}`;
      }
      let label = 'Book event';
      if (eventsToBook.value?.length > 1) label += 's';
      return `${label}`;
    });

    const suspendEventsLabel = computed(() => {
      if (hasEventsToBook.value || selectedEvents.value.length !== eventsToSuspend.value.length) {
        let label = `Suspend ${eventsToSuspend.value.length} event`;
        if (eventsToSuspend.value?.length > 1) label += 's';
        return `${label}`;
      }
      let label = 'Suspend event';
      if (eventsToSuspend.value?.length > 1) label += 's';
      return `${label}`;
    });

    const unsuspendEventsLabel = computed(() => {
      if (hasEventsToBook.value || selectedEvents.value.length !== eventsToUnsuspend.value.length) {
        let label = `Unsuspend ${eventsToUnsuspend.value.length} event`;
        if (eventsToUnsuspend.value?.length > 1) label += 's';
        return `${label}`;
      }
      let label = 'Unsuspend event';
      if (eventsToUnsuspend.value?.length > 1) label += 's';
      return `${label}`;
    });

    const tradingButtonLabel = computed(() => {
      if (selectedEvents.value.length > 1) {
        return 'Open Trading Multiview';
      }
      return 'Trade Event';
    });

    const clearSelection = () => {
      store.dispatch('updateSelectedEvents', []);
    };

    const suspendSelection = () => {
      const ids = eventsToSuspend.value?.map(({ eventId }) => eventId);
      store.dispatch('toggleBatchSuspend', { eventsIds: ids, suspend: true });
    };

    const unsuspendSelection = () => {
      const ids = eventsToUnsuspend.value?.map(({ eventId }) => eventId);
      store.dispatch('toggleBatchSuspend', { eventsIds: ids, suspend: false });
    };

    const bookSelection = async () => {
      processing.value = true;
      await Promise.all(
        map(eventsToBook.value, (event) => new Promise(
          (resolve) => bookAllFeaturesEvent(event.eventId)
            .then(() => {
              store.dispatch('loadEvents');
              store.dispatch('addNotification', {
                message: 'Successfully booked event(s)',
                type: 'success',
                duration: 5000,
              });
            })
            .catch(() => {
              console.error('Could not book event:', event.id);
            })
            .finally(resolve),
        )),
      );
      clearSelection();
      processing.value = false;
    };

    const openTradingView = () => {
      const isSingleEvent = selectedEvents.value.length === 1;
      const routeName = isSingleEvent ? 'trading-ui' : 'trading-ui-multiview';
      const routeParams = isSingleEvent
        ? { params: { eventId: selectedEvents.value[0].eventId } }
        : { query: { ids: selectedEvents.value.map((event) => event.eventId) } };

      const routeData = router.resolve({
        name: routeName,
        ...routeParams,
      });

      window.open(routeData.href, '_blank');
    };

    return {
      hasSelectedEvents,
      selectedEventsLabel,
      isEventsPage,
      clearSelection,
      openTradingView,
      bookSelection,
      eventsToBook,
      processing,
      eventsToSuspend,
      hasEventsToBook,
      hasEventsToSuspend,
      bookEventsLabel,
      suspendEventsLabel,
      suspendSelection,
      tradingButtonLabel,
      unsuspendSelection,
      hasEventsToUnsuspend,
      unsuspendEventsLabel,
      selectedEvents,
    };
  },
};
</script>

<style lang="scss">
.events-page-prompt {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 24px 16px;
  position: absolute;
  background: #FFFFFF;
  border: 1px solid $gray600;
  box-sizing: border-box;
  box-shadow: 0px 8px 12px -6px rgba(0, 0, 0, 0.12);
  border-radius: 8px;
  left: 50%;
  transform: translateX(-50%);
  transform-origin: center;
  max-width: 55%;
  min-width: 400px;
  height: 65px;
  bottom: 80px;
  z-index: 1110;
}

.events-page-prompt-enter-active {
  animation: events-page-prompt-bounce-in .5s;
}

.events-page-prompt-leave-active {
  animation: events-page-prompt-bounce-in .25s reverse;
}

@keyframes events-page-prompt-bounce-in {
  0% {
    transform: translateY(100%) translateX(-50%);
  }
  50% {
    transform: translateY(-10%) translateX(-50%);
  }
  100% {
    transform: translateY(0%) translateX(-50%);
  }
}

.events-page-prompt-box {
  display: flex;
  align-items: center;

  & > * {
    margin: 0 7px;

    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      margin-right: 0;
    }
  }
}
.events-page-prompt-box:nth-child(1) {
  margin-right: 2rem;
}
.open-multiview-button {
  display: inline-flex;
  align-items: center;
  background-color: $sidebarTogglerBackgroundColor;
  border-color: $sidebarTogglerBackgroundColor;
  color: $sidebarTogglerTextColor;
  border-radius: $buttonBorderRadius;
  padding: $buttonPadding;
  font-size: $buttonFontSize;
  transition: $buttonBoxShadowTransition;
  user-select: none;
  height: $buttonHeight;
  white-space: nowrap;
  cursor: pointer;

  & > .icon {
    margin-right: 4px;
    fill: #fff;
  }

  &:hover,
  &:focus,
  &:active {
    background-color: $sidebarTogglerHoverBackgroundColor;
    border-color: $sidebarTogglerHoverBackgroundColor;
  }

  &:focus {
    outline: none;
    box-shadow: $componentFocusBoxShadow;
    background-color: $sidebarTogglerHoverBackgroundColor;
    border-color: $sidebarTogglerHoverBackgroundColor;
  }
}
.suspend-btn {
  color: $error500;
  .icon {
    svg path {
      fill: $error500;
    }
  }
}
</style>
