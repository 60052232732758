<template>
  <div
    class="event-table-context-menu"
    :style="{
      top: `${top}px`,
      left: `${left}px`,
    }"
  >
    <div
      class="event-table-context-menu__action"
      @click="showEventDetails"
    >
      Show event details
    </div>
    <div
      v-if="event.manuallyCreated && !isEventFinished && !eventDisabled"
      class="event-table-context-menu__action"
      @click="updateEvent"
    >
      Update event
    </div>
    <div
      v-if="isPlayerSetupAvailable && !eventDisabled"
      class="event-table-context-menu__action"
      @click.left="openPlayerSetup"
      @click.middle="openPlayerSetup('_blank')"
    >
      Params setup
    </div>
    <div
      class="event-table-context-menu__action"
      @click.left="openTradingUi"
      @click.middle="openTradingUi('_blank')"
      v-if="!eventDisabled"
    >
      Trade event
    </div>
    <div
      v-if="showEventReplay && !eventDisabled"
      class="event-table-context-menu__action"
      @click="openEventReplay"
    >
      Schedule event replay
    </div>
    <div
      v-if="isEventBookingSupported && !eventDisabled"
      class="event-table-context-menu__action"
      @click="openSubscriptionModal"
    >
      Customized booking
    </div>
    <div
      v-if="showFastForwardReplay && !eventDisabled"
      class="event-table-context-menu__action"
      @click="fastForwardReplay"
    >
      Fast forward replay
    </div>
    <div
      class="event-table-context-menu__action"
      @click.left="openAuditLog"
      @click.middle="openAuditLog('_blank')"
      v-if="auditLogEnabled && !eventDisabled"
    >
      Audit log
    </div>
    <div
      v-if="!eventDisabled && !isEventFinished && superAdminEnabled"
      class="event-table-context-menu__action warning"
      @click="disableEvent"
    >
      <!-- <icon name="disable-toggle" /> -->
      Disable event
    </div>
    <ConfirmDisableEventPopup
      v-if="disableEventCheck"
      :event-name="eventName"
      @onLeaveCancel="() => { disableEventCheck = false; }"
      @onLeaveConfirm="confirmDisableEvent"
    />
  </div>
</template>

<script>
import { computed, provide, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useStore } from 'vuex';
import { includes } from 'lodash';
import sportIds from '@/services/helpers/sports';
import { subscriptionTypes } from '@/services/helpers/subscriptions';
import { getSuperAdminData } from '@/services/helpers/super-admin';
/* import Icon from '@/components/common/Icon'; */
import ConfirmDisableEventPopup from './ConfirmDisableEventPopup';
import { isAllowedUserRole } from '@/services/helpers/navbar';
import env from '@/../config/env';
import { getAllowedRoles } from '@/../config/userRoles';

const {
  BASKETBALL_ID,
  FOOTBALL_ID,
  HOCKEY_ID,
  BASEBALL_ID,
} = sportIds;

export default {
  components: {
    ConfirmDisableEventPopup,
    /* Icon, */
  },
  props: {
    top: {
      type: Number,
      default: 0,
    },
    left: {
      type: Number,
      default: 0,
    },
    event: {
      type: Object,
      required: true,
    },
  },
  emits: {
    hide: {},
    replay: {},
    updateEvent: {},
  },
  setup(props, { emit }) {
    const route = useRoute();
    const router = useRouter();
    const store = useStore();

    const { isSuperAdmin, SUPER_ADMIN_CLIENT } = getSuperAdminData();
    const auditLogEnabled = computed(() => isSuperAdmin);

    provide('isMenuOpen', true);

    const eventDisabled = computed(() => props.event.limbo);
    const eventName = computed(() => {
      if (!props.event?.isUsaView) return props.event?.eventName;
      return props.event?.eventName.split(' v ').reverse().join(' @ ');
    });

    const showEventDetails = () => {
      const { event, ...query } = route.query;
      router.push({
        name: route.name,
        query: {
          ...query,
          event: props.event.eventId,
        },
      });
      emit('hide');
    };

    const isPlayerSetupAvailable = computed(() => {
      const sportHasPlayerSetup = includes([
        BASKETBALL_ID,
        FOOTBALL_ID,
        HOCKEY_ID,
        BASEBALL_ID,
      ], props.event.sportId);

      const allowedUserRoles = getAllowedRoles('playerSetup');
      return sportHasPlayerSetup && isAllowedUserRole(allowedUserRoles);
    });
    const openPlayerSetup = (target = '') => {
      const routeOptions = {
        name: 'params-setup',
        params: {
          eventId: props.event.eventId,
        },
      };

      if (target === '_blank') {
        const url = router.resolve(routeOptions).href;
        window.open(url, target);
        emit('hide');
        return;
      }

      router.push(routeOptions);
      emit('hide');
    };

    const openTradingUi = (target = '') => {
      const routeOptions = {
        name: 'trading-ui',
        params: {
          eventId: props.event.eventId,
        },
      };

      if (target === '_blank') {
        const url = router.resolve(routeOptions).href;
        window.open(url, target);
        emit('hide');
        return;
      }

      router.push(routeOptions);
      emit('hide');
    };

    const isEventReplaySupported = computed(() => !!env.replay);
    const isEventFinished = computed(() => props.event.matchState === 'FINISHED');
    const showEventReplay = computed(() => isEventReplaySupported.value && isEventFinished.value);
    const showFastForwardReplay = computed(() => props.event.eventType === 'REPLAY' && !isEventFinished.value);

    const openEventReplay = () => {
      emit('replay', props.event);
      emit('hide');
    };

    const isEventBookingSupported = computed(() => props.event.eventIsCoveredsByEventId?.nodes?.[0]?.available);
    const openSubscriptionModal = () => {
      store.dispatch('openSubscriptionModal', {
        type: subscriptionTypes.EVENT,
        id: props.event.eventId,
      });
      emit('hide');
    };

    const fastForwardReplay = () => {
      store.dispatch('fastForwardReplay', {
        eventId: props.event.eventId,
      });
      emit('hide');
    };

    const openAuditLog = (target = '') => {
      const routeOptions = {
        name: 'audit-log',
        params: {
          eventId: props.event.eventId,
        },
      };

      if (target === '_blank') {
        const url = router.resolve(routeOptions).href;
        window.open(url, target);
        emit('hide');
        return;
      }

      router.push(routeOptions);
      emit('hide');
    };

    const disableEventCheck = ref(false);

    const disableEvent = () => {
      disableEventCheck.value = true;
    };

    const confirmDisableEvent = () => {
      store.dispatch('disableEvent', props.event.eventId);
      emit('hide');
    };

    const updateEvent = () => {
      emit('updateEvent');
    };

    const selectedOperator = computed(() => route.query.client);
    const superAdminEnabled = computed(() => isSuperAdmin && selectedOperator.value === SUPER_ADMIN_CLIENT);

    return {
      showEventDetails,
      isPlayerSetupAvailable,
      openPlayerSetup,
      openTradingUi,
      showEventReplay,
      openEventReplay,
      isEventBookingSupported,
      openSubscriptionModal,
      showFastForwardReplay,
      fastForwardReplay,
      openAuditLog,
      auditLogEnabled,
      disableEvent,
      eventDisabled,
      disableEventCheck,
      confirmDisableEvent,
      eventName,
      updateEvent,
      isEventFinished,
      superAdminEnabled,
    };
  },
};
</script>

<style lang="scss">
.event-table-context-menu {
  display: flex;
  flex-direction: column;
  position: fixed;
  background-color: $white;
  color: $black;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  border: 1px solid rgba(221, 221, 221, 1);
  z-index: $dropdownMenuZIndex;

  &__action {
    display: inline-flex;
    align-items: center;
    white-space: nowrap;
    min-width: 100%;
    height: 44px;
    padding: 0 12px;
    box-shadow: inset 0px -1px 0px #F0F0F0;
    cursor: pointer;

    &:last-of-type {
      box-shadow: none;
    }

    &:hover,
    &:focus {
      background-color: #FAFAFA;
      outline: none;
    }

    &.warning {
      color: #FF2E2D;
    }
  }
}
</style>
